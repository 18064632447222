//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import IamNewForm from '@/modules/iam/components/iam-new-form';
import { routerAsync } from '@/app-module';
import i18n from '@/vueI18n'

export default {
  name: 'app-iam-new-page',

  components: {
    [IamNewForm.name]: IamNewForm,
  },

  computed: {
    ...mapGetters({
      saveLoading: 'iam/form/saveLoading',
    }),
    language(){
      return i18n.locale
    },

    isSingleEmail() {
      return (
        !this.model ||
        !this.model.emails ||
        this.model.emails.length <= 1
      );
    },
  },
  watch: {
    language() {
               document.title =
            this.i18n('app.title') +
            ' | ' +
            this.i18n('menu.users') +
            ' | ' +
            this.i18n('iam.new.title') 
    },
  },

  async created() {
    await this.doNew();
  },

  methods: {
    ...mapActions({
      doNew: 'iam/form/doNew',
      doAdd: 'iam/form/doAdd',
    }),

    doCancel() {
      routerAsync().push('/iam');
    },

    async doSubmit(payload) {
      return this.doAdd(payload.values);
    },
     i18n(key, args) {
      return this.$t(key, args);
    },
  },
};
